import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import TileAttribute from '../../general/TileAttribute';
import Link from '../../navigation/Link';
import Date from '../../general/Date';
import DateTime from '../../general/DateTime';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../constants/Rights';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {navigateToParametrized, getQueryParam} from '../../../lib/url';
import {numberOrNull} from '../../../lib/number';
import {isTradeSell, getBackgroundStyle} from '../../../lib/project';
import {formatPrice, formatProductName, formatQuantity, formatUnitPrice} from '../../../lib/formatting';
import {Order, OrderDetailLine, Trade} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import RemoveOrder from './RemoveOrder';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import CustomerLink from '../../general/CustomerLink';
import UserLink from '../../general/UserLink';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import { createFetchOrderDetails } from '../../../backend/apiCalls';
import SimpleTable from '../../general/SimpleTable';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { Button, Icon } from 'antd';
import { ORDER_STATE, ORDER_LINE_STATE } from '../../../constants/orderStates';
import NavigateButton from '../../general/NavigateButton';

/**
 * @fero
 */

class OrdersTable extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        reload: PropTypes.func.isRequired,
        orders: PropTypes.arrayOf(Order.isRequired).isRequired,
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_ORDER_BY]: newOrder,
            [QUERY_PARAMS.ORDER_SCROLL]: 0,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_LIMIT]: newLimit,
        });
    };

    render() {
        const {location, reload, orders, rights} = this.props;
        const orderBy = getQueryParam(location, QUERY_PARAMS.ORDER_ORDER_BY);
        const currentLimit = numberOrNull(getQueryParam(location, QUERY_PARAMS.ORDER_LIMIT));
        return <Table
            className={rights >= RIGHTS.MARKETING ? "orders-table-dealer" : "orders-table-customer"}
            BodyRow={OrdersTableRowWrapped}
            BodyTile={OrderTile}
            data={orders}
            limit={currentLimit}
            pageSize={!Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20}
            changeLimit={this.setLimit}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            reload={reload}
            noTBody={true}
            colDefs={[
                {
                    class: 'orders-table-col-arrow',
                },
                {
                    headerCell: <Trans>Č. objednávky</Trans>,
                    orderCol: 'dealer_reference',
                    class: 'orders-table-col-dealer-ref'
                },
                {
                    headerCell: <React.Fragment>
                        <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                            <Trans>Vaše č. objednávky</Trans>
                        </RightsWrapper>
                        <RightsWrapper from={RIGHTS.MARKETING}>
                            <Trans>Č. obj. organizácie</Trans>
                        </RightsWrapper>
                    </React.Fragment>,
                    orderCol: 'customer_reference',
                    class: 'orders-table-col-customer-ref'
                },
                {
                    headerCell: <Trans>Organizácia</Trans>,
                    orderCol: 'customer',
                    class: 'orders-table-col-customer',
                    rightsFrom: RIGHTS.MARKETING,
                },
                {
                    headerCell: <Trans>Kontakt</Trans>,
                    orderCol: 'user',
                    class: 'orders-table-col-user',
                    rightsFrom: RIGHTS.MARKETING,
                },
                {
                    headerCell: <Trans>Vybavuje</Trans>,
                    orderCol: 'dealer',
                    class: 'orders-table-col-dealer'
                },
                {
                    headerCell: <Trans>Zodp. obchodník a logista</Trans>,
                    orderCol: 'salesman',
                    class: 'orders-table-col-dealer',
                    rightsFrom: RIGHTS.MARKETING
                },
                {
                    headerCell: <Trans>Stav</Trans>,
                    orderCol: 'id_status',
                    class: 'orders-table-col-status'
                },
                {
                    headerCell: <Trans>Odoslané</Trans>,
                    orderCol: 'sent_at_or_now',
                    descFirst: true,
                    class: 'orders-table-col-sent-at'
                },
                {
                    headerCell: <Trans>Dátum potreby</Trans>,
                    orderCol: 'date_requested',
                    descFirst: true,
                    class: 'orders-table-col-date-requested',
                    rightsFrom: RIGHTS.MARKETING
                },
                {
                    headerCell: <Trans>Cena bez DPH</Trans>,
                    orderCol: 'sum_netto',
                    class: 'orders-table-col-price'
                },
                {
                    headerCell: <Trans>Poznámka</Trans>,
                    class: 'orders-table-col-customer-notices'
                },
                {
                    class: 'orders-table-col-actions',
                    headerCell: <div className="d-flex align-items-center">
                        <RightsWrapper from={RIGHTS.MARKETING}>
                            <NavigateButton
                                to={ROUTES.NEW_ORDER}
                                tooltipTitle={<Trans>Vytvoriť novú objednávku</Trans>}
                                size="small"
                            >
                                <Icon type="plus"/>
                            </NavigateButton>
                        </RightsWrapper>
                    </div>
                },
            ]}
        />;
    }

}

export default withSessionHOC(['rights'])(locationHOC(OrdersTable));

class OrdersTableRow extends React.PureComponent {
    static propTypes = {
        data: Order.isRequired,
        reload: PropTypes.func.isRequired,
        [SESSION_ATTRIBUTES.RIGHTS]: PropTypes.number.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            lines: [],
            orderBy: undefined,
        }
    }

    componentDidMount() {
        this.setState({
            opened: false,
            lines: [],
            orderBy: undefined,
        });
    }

    componentDidUpdate(prevProps) {
        const {data: prevData} = prevProps;
        const {id: prevOrderId} = prevData;
        const {data} = this.props;
        const {id: orderId} = data;

        if(orderId != prevOrderId) {
            this.setState({
                opened: false,
                lines: [],
                orderBy: undefined,
            });
        }
    }

    fetchLines = () => {
        const {data, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const {id: orderId} = data;
        const {orderBy} = this.state;

        fetchHandler(
            createFetchOrderDetails(),
            {
                id: orderId,
                order: orderBy,
            },
            (result) => {
                const lines = result != null && result.lines != null ? result.lines : [];
                this.setState({ lines: lines });
            }
        );
    }

    toggleLines = () => {
        const {opened} = this.state;
        const newOpened = !opened;
        this.setState({opened: newOpened});
        if(newOpened)
            this.fetchLines();
    }

    changeOrder = (newOrder) => {
        this.setState({orderBy: newOrder});
    }


    render() {
        const {data, reload, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const {opened, lines, orderBy} = this.state;
        const finalCustomerName = data.final_order != null && data.final_order.customer != null ? data.final_order.customer.name : null;

        return <tbody className={getBackgroundStyle(data.trade, {isExternal: data.is_external, isNotCovered: data.id_status == ORDER_STATE.NOT_COVERED})}>
        <tr>
            <td rowSpan={opened ? 2 : 1}>
                <Button
                    size="small"
                    onClick={this.toggleLines}
                    icon={opened ? 'down' : 'right'}
                />
            </td>
            <td className="overflow-wrap-break-word">
                <Link
                    className="px-2 text-dark font-weight-bold"
                    to={ROUTES.ORDER_DETAILS}
                    queryParams={{[QUERY_PARAMS.ID_ORDER]: data.id}}
                    title={<Trans>Detail objednávky</Trans>}
                >
                    {data.dealer_reference}
                </Link>
            </td>
            <td className="overflow-wrap-break-word">{data.customer_reference}</td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <CustomerLink
                        customerId={data.id_customer}
                        customerName={data.customer}
                    />
                    { finalCustomerName != null ?
                        <div className="table-subdata">
                            <Trans>Príjemca:</Trans>
                            <span className="ml-2">{finalCustomerName}</span>
                        </div>
                        : null
                    }
                </td>
                <td>
                    <UserLink
                        userId={data.id_user}
                        userName={data.user}
                    />
                </td>
            </RightsWrapper>
            <td>{data.dealer}</td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <div>{data.salesman}</div>
                    <div>{data.salesman2}</div>
                </td>
            </RightsWrapper>
            <td>{data.status}</td>
            <td className="text-right"><DateTime timeString={data.sent_at}/></td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td className="text-right">
                    <div><Date dateString={data.date_requested}/></div>
                    { data.is_critical == 1 ? 
                        <div className="color-red">
                            <Trans>URGENTNÉ</Trans>
                        </div>
                        : null
                    }
                </td>
            </RightsWrapper>
            <td className="text-right">
                {formatPrice(data.sum_netto, data.id_currency)}
                <RightsWrapper from={RIGHTS.MARKETING}>
                    { Number(data.prepayment) >= Number(data.sum_brutto) && Number(data.sum_brutto) > 0.0 ?
                        <div>
                            <Trans>(zaplatené)</Trans>
                        </div> : 
                        null
                    }
                </RightsWrapper>
            </td>
            <td>
                <div className="text-overflow-ellipsis">{data.customer_notices}</div>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    {data.dealer_notices != null ? <div className="font-italic text-overflow-ellipsis">{data.dealer_notices}</div> : null}
                </RightsWrapper>
            </td>
            <td>
                <div className="d-flex justify-content-center flex-wrap">
                    <RemoveOrder
                        size="small"
                        order={data}
                        onFinishSuccessful={reload}
                    />
                </div>
            </td>
        </tr>
        {opened ? 
            <tr>
                <td colSpan={rights >= RIGHTS.MARKETING ? 12 : 8}>
                    <OrderLinesSimpleTable
                        lines={lines}
                    />
                </td>
            </tr> : 
            null
        }
        </tbody>;
    }
}

const OrdersTableRowWrapped = withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(OrdersTableRow));

class OrderTile extends React.PureComponent {
    static propTypes = {
        data: Order.isRequired,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {data, reload} = this.props;
        return <Tile className={"p-2 " + getBackgroundStyle(data.trade, {isExternal: data.is_external, isNotCovered: data.id_status == ORDER_STATE.NOT_COVERED})}>
            <div className="flex-row-dynamic-static">
                <div className="align-self-center">
                    <Link
                        className="text-dark"
                        to={ROUTES.ORDER_DETAILS}
                        queryParams={{[QUERY_PARAMS.ID_ORDER]: data.id}}
                    >
                        <h4 className="px-2 mt-1">{data.dealer_reference}</h4>
                    </Link>
                    <TileAttribute
                        title={
                            <React.Fragment>
                                <RightsWrapper from={RIGHTS.MARKETING}>
                                    <Trans>Č. obj. organizácie</Trans>
                                </RightsWrapper>
                                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                                    <Trans>Vaše č. objednávky</Trans>
                                </RightsWrapper>
                            </React.Fragment>
                        }
                        value={data.customer_reference}
                    />
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <TileAttribute
                            title={<Trans>Organizácia</Trans>}
                            value={
                                <CustomerLink
                                    customerId={data.id_customer}
                                    customerName={data.customer}
                                />
                            }
                        />
                        <TileAttribute
                            title={<Trans>Kontakt</Trans>}
                            value={
                                <UserLink
                                    userId={data.id_user}
                                    userName={data.user}
                                />
                            }
                        />
                    </RightsWrapper>
                    <TileAttribute
                        title={<Trans>Vybavuje</Trans>}
                        value={data.dealer}
                    />
                </div>
                <RemoveOrder
                    buttonClassName="m-1"
                    size="small"
                    order={data}
                    onFinishSuccessful={reload}
                />
            </div>
            <TileAttribute
                title={<Trans>Suma bez DPH</Trans>}
                value={
                    <div className="text-right">
                        {formatPrice(data.sum_netto, data.id_currency)}
                        <RightsWrapper from={RIGHTS.MARKETING}>
                            { Number(data.prepayment) >= Number(data.sum_brutto) && Number(data.sum_brutto) > 0.0 ?
                                <div>
                                    <Trans>(zaplatené)</Trans>
                                </div> : 
                                null
                            }
                        </RightsWrapper>
                    </div>
                }
            />
            <TileAttribute
                title={<Trans>Stav</Trans>}
                value={data.status}
            />
            <TileAttribute
                title={<Trans>Odoslané</Trans>}
                value={<DateTime timeString={data.sent_at}/>}
            />
            <TileAttribute
                title={<Trans>Dátum potreby</Trans>}
                value={<DateTime timeString={data.date_requested}/>}
            />
            <TileAttribute
                title={<Trans>Poznámka</Trans>}
                value={<div className="text-overflow-ellipsis">{data.customer_notices}</div>}
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    title={<Trans>Interná poznámka</Trans>}
                    value={<div className="text-overflow-ellipsis">{data.dealer_notices}</div>}
                />
            </RightsWrapper>
        </Tile>;
    }
}

class OrderLinesSimpleTable extends React.PureComponent {
    static propTypes = {
        lines: PropTypes.arrayOf(OrderDetailLine).isRequired,
    };

    render() {
        const {trade, lines} = this.props;
        return <SimpleTable
            className="simple-table-lines"
            data={lines}
            rowStyleGenerator={(data) => {
                return getBackgroundStyle(trade, {
                    isAutoSupply: data.auto_supply,
                    isService: data.is_service, 
                    isNotCovered: data.id_status == ORDER_LINE_STATE.NOT_COVERED
                });
            }}
            colDefs={[
                {
                    dataKey: 'sequence',
                    class: 'order-lines-table-col-sequence'
                },
                {
                    render: (props) => { return <Link
                            className="text-dark"
                            to={ROUTES.PRODUCT_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_PRODUCT]: props.id_product}}
                            title={<Trans>Zobraziť detail produktu</Trans>}
                        >
                            {formatProductName(props.designation, props.manufacturer)}
                        </Link>
                    },
                    class: 'order-lines-table-col-designation'
                },
                {
                    render: (props) => { 
                        return <div className="text-right">
                            <div>
                                {formatQuantity(props.package_quantity, props.package_type)}
                            </div>
                            { Number(props.package_quantity_to_process) > 0 ?
                                <div className="table-subdata">
                                    {' ('}
                                    <Trans>nedodané:</Trans>
                                    {' '}
                                    {formatQuantity(props.package_quantity_to_process, props.package_type)}
                                    {')'}
                                </div> :
                                null
                            }
                        </div>
                    },
                    class: 'order-lines-table-col-quantity text-right'
                },
                {
                    render: (props) => { return formatUnitPrice(props.package_price, props.package_type, props.id_currency)},
                    class: 'order-lines-table-col-price text-right'
                },
                {
                    render: (props) => { return <div className="d-flex">
                            <Trans>DP:</Trans>
                            {' '}
                            <Date dateString={props.requested_at}/>
                        </div>
                    },
                    rightsFrom: RIGHTS.MARKETING,
                    class: 'order-lines-table-col-requested-at'
                },
                {
                    render: (props) => { return <div className="d-flex">
                            <Trans>DT:</Trans>
                            {' '}
                            <Date dateString={props.available_at}/>
                        </div>
                    },
                    class: 'order-lines-table-col-requested-at'
                },
                {
                    dataKey: 'status',
                    class: 'order-lines-table-col-status'
                },
                {
                    class: 'order-lines-table-col-notice',
                    render: (props) => { return <span>
                            {props.customer_notice}
                            <span className="pl-2 font-italic">{props.dealer_notice}</span>
                        </span>
                    }
                },
            ]}
        />
    }
}