import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import {SimpleColDef} from '../../constants/propTypesDefinitions';

/**
 * SimpleTable is a component for displaying simple table data without header.
 * For every column attributes may be specified:
 *      a data keys which specify which values from data should be used in render method.
 *      a render method may be specified(function that returns react node). If render method is not specified a all data
 *      specified by data keys will be concatenated as string adn displayed.
 *      rights specifying from and to which rights should column be visible.
 *      class that is ised ass class for td wrapping content(produced by render method).
 *
 * Data structure is specified in SimpleColDef in /constants/propTypesDefinitions.
 *
 * Class may be specified for root of table(table tag) by className prop. This class will be added to basic styling
 * class of SimpleTable.
 *
 * @fero
 */

class SimpleTable extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        data: PropTypes.array.isRequired,
        colDefs: PropTypes.arrayOf(SimpleColDef.isRequired).isRequired,
        rowStyleGenerator: PropTypes.func,
        indexKey: PropTypes.string,
    };

    static defaultProps = {
        className: "simple-table-default"
    };

    cellElement = (colDef, rowIdx, dataItem) => {
        if (colDef.render != null) 
        {
            return colDef.render(dataItem, rowIdx);
        } 
        else if(colDef.dataKey != null)
        {
            return dataItem[colDef.dataKey];
        }
        else
        {
            return null;
        }
    };

    render() {
        const {colDefs, data, className, rowStyleGenerator, indexKey, ...props} = this.props;
        return <table className={"simple-table " + className} {...props}>
            <tbody>
            {data.map((dataItem, rowIndex) => {
                const rowClass = rowStyleGenerator != null ? rowStyleGenerator(dataItem) : undefined;
                const index = indexKey != null ? dataItem[indexKey] : (dataItem.id ?? dataItem.id_item ?? rowIndex);
                return <tr key={index} className={rowClass}>
                    {colDefs.map((colDef, index2) => {
                        return <RightsWrapper
                            key={index + index2}
                            from={colDef.rightsFrom}
                            to={colDef.rightsTo}
                        >
                            <td className={colDef.class}>
                                {this.cellElement(colDef, rowIndex, dataItem)}
                            </td>
                        </RightsWrapper>;
                    })}
                </tr>
            })}
            </tbody>
            <colgroup>
                {colDefs.map((colDef, i) => {
                    return <col key={i} style={colDef.style}/>
                })}
            </colgroup>
        </table>;
    }

}

export default SimpleTable;