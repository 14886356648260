import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import InputText from '../../general/InputText';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import OrderStatesSelect from '../../project/OrderStatesSelect';
import CustomersSelect from '../../project/CustomersSelect';
import EmployeesSelect from '../../project/EmployeesSelect';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import DateRangePicker from '../../general/DateRangePicker';
import {getQueryParam, createSetQueryParamValue, navigateToParametrized, getQueryParamNumber} from '../../../lib/url';
import {getInputOnChangeEventChecked, getInputOnChangeEventValue} from '../../../lib/project';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {TRADE} from '../../../constants/trade';
import {Button, Checkbox, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import DepartmentsSelect from '../../project/DepartmentsSelect';
import DealCodeSelect from '../../project/DealCodeSelect';

/**
 * @fero
 */

export const ORDER_CLEAR_PARAMS = {
    [QUERY_PARAMS.ORDER_TRADE]: undefined,
    [QUERY_PARAMS.ORDER_REF]: undefined,
    [QUERY_PARAMS.ORDER_CUSTOMER_REFERENCE]: undefined,
    [QUERY_PARAMS.ORDER_DEAL_CODE]: undefined,
    [QUERY_PARAMS.ORDER_ID_DEALER]: undefined,
    [QUERY_PARAMS.ORDER_ID_SALESMAN]: undefined,
    [QUERY_PARAMS.ORDER_ID_SALESMAN2]: undefined,
    [QUERY_PARAMS.ORDER_ID_USER]: undefined,
    [QUERY_PARAMS.ORDER_ID_CUSTOMER]: undefined,
    [QUERY_PARAMS.ORDER_ID_STATUS]: undefined,
    [QUERY_PARAMS.ORDER_NOTICES]: undefined,
    [QUERY_PARAMS.ORDER_DESIGNATION]: undefined,
    [QUERY_PARAMS.ORDER_CREATED_AT]: undefined,
    [QUERY_PARAMS.ORDER_LAST_CHANGE]: undefined,
    [QUERY_PARAMS.ORDER_DATE_REQUESTED]: undefined,
    [QUERY_PARAMS.ORDER_DEPARTMENT]: undefined,
    [QUERY_PARAMS.ORDER_SHOW_REMOTE]: undefined,
    [QUERY_PARAMS.ORDER_ORDER_BY]: undefined,
    [QUERY_PARAMS.ORDER_SCROLL]: undefined,
    [QUERY_PARAMS.ORDER_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
};

class OrdersParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, ORDER_CLEAR_PARAMS);
    };

    render() {
        const {location} = this.props;
        const trade = getQueryParam(location, QUERY_PARAMS.ORDER_TRADE);
        const orderRef = getQueryParam(location, QUERY_PARAMS.ORDER_REF);
        const customerReference = getQueryParam(location, QUERY_PARAMS.ORDER_CUSTOMER_REFERENCE);
        const dealCode = getQueryParam(location, QUERY_PARAMS.ORDER_DEAL_CODE);
        const dealerId = getQueryParam(location, QUERY_PARAMS.ORDER_ID_DEALER);
        const salesmanId = getQueryParam(location, QUERY_PARAMS.ORDER_ID_SALESMAN);
        const salesman2Id = getQueryParam(location, QUERY_PARAMS.ORDER_ID_SALESMAN2);
        const userId = getQueryParam(location, QUERY_PARAMS.ORDER_ID_USER);
        const customerId = getQueryParam(location, QUERY_PARAMS.ORDER_ID_CUSTOMER);
        const departmentId = getQueryParam(location, QUERY_PARAMS.ORDER_DEPARTMENT);
        const showRemote = getQueryParamNumber(location, QUERY_PARAMS.ORDER_SHOW_REMOTE);
        const statusId = getQueryParam(location, QUERY_PARAMS.ORDER_ID_STATUS);
        const notices = getQueryParam(location, QUERY_PARAMS.ORDER_NOTICES);
        const designation = getQueryParam(location, QUERY_PARAMS.ORDER_DESIGNATION);
        const createAtDateString = getQueryParam(location, QUERY_PARAMS.ORDER_CREATED_AT);
        const lastChangeDateString = getQueryParam(location, QUERY_PARAMS.ORDER_LAST_CHANGE);
        const dateRequestedDateString = getQueryParam(location, QUERY_PARAMS.ORDER_DATE_REQUESTED);
        const hasFilter = trade != null || orderRef != null || customerReference != null || dealerId != null || departmentId != null ||
            userId != null || customerId != null || statusId != null || notices != null || createAtDateString != null || designation != null ||
            salesmanId != null || salesman2Id != null || lastChangeDateString != null || dateRequestedDateString != null || dealCode != null;
        return <ColumnFilterLayout
            filters={[
                [
                    {
                        label: <Trans>Zobraziť: </Trans>,
                        selector: <Radio.Group
                            onChange={() => {
                                navigateToParametrized(location, ROUTES.ORDER_LINES, {})
                            }}
                            value={"list"}
                        >
                            <Radio.Button value={"list"}><Trans>Zoznam</Trans></Radio.Button>
                            <Radio.Button value={"lines"}><Trans>Riadky objednávok</Trans></Radio.Button>
                        </Radio.Group>    
                    },
                    {
                        label: <Trans>Typ: </Trans>,
                        selector: <div>
                            <Radio.Group
                                onChange={(e) => {
                                    const value = getInputOnChangeEventValue(e);
                                    createSetQueryParamValue(location, QUERY_PARAMS.ORDER_TRADE)(value);
                                }}
                                value={trade}
                            >
                                <Radio.Button value={TRADE.SELL}><Trans>Prijaté</Trans></Radio.Button>
                                <Radio.Button value={TRADE.BUY}><Trans>Odoslané</Trans></Radio.Button>
                                <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                            </Radio.Group>
                            <Checkbox
                                className="p-1"
                                onChange={(ev) => {
                                    const val = getInputOnChangeEventChecked(ev) ? 1 : 0;
                                    createSetQueryParamValue(location, QUERY_PARAMS.ORDER_SHOW_REMOTE)(val);
                                }}
                                checked={showRemote == 1}
                            >
                                <Trans>aj zrkadlené objednávky</Trans>
                            </Checkbox>
                        </div>,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Číslo objednávky: </Trans>,
                        selector: <InputText
                            value={orderRef}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_REF)}
                            allowClear={true}
                        />,
                    },
                    {
                        label: [
                            <RightsWrapper key="refCustomer" to={RIGHTS.DISTRIBUTOR}>
                                <Trans>Vaše číslo objednávky: </Trans>
                            </RightsWrapper>,
                            <RightsWrapper key="refDealer" from={RIGHTS.MARKETING}>
                                <Trans>Číslo obj. organizácie: </Trans>
                            </RightsWrapper>
                        ],
                        selector: <InputText
                            value={customerReference}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_CUSTOMER_REFERENCE)}
                            allowClear={true}
                        />,
                    },
                    {
                        label: <Trans>Poznámka: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_NOTICES)}
                            value={notices}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Stav: </Trans>,
                        selector: <OrderStatesSelect
                            className="full-size-width"
                            value={statusId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_ID_STATUS)}
                            allowClear={true}
                        />,
                    },
                ],
                [
                    {
                        label: <Trans>Organizácia: </Trans>,
                        selector: <CustomersSelect
                            className="full-size-width"
                            value={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_ID_CUSTOMER)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Kontakt: </Trans>,
                        selector: <UserSelectByCustomer
                            className="full-size-width"
                            value={userId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_ID_USER)}
                            allowClear={true}
                            customerId={customerId}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Vybavuje: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={dealerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_ID_DEALER)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Zodp. obchodník: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesmanId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_ID_SALESMAN)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Logista: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesman2Id}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_ID_SALESMAN2)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Oddelenie: </Trans>,
                        selector: <DepartmentsSelect
                            className="full-size-width"
                            value={departmentId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_DEPARTMENT)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Hľadaj produkt:</Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_DESIGNATION)}
                            value={designation}
                            allowClear={true}
                        />,
                        rightsTo: RIGHTS.DISTRIBUTOR
                    },
                ],
                [
                    {
                        label: <Trans>Hľadaj produkt:</Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_DESIGNATION)}
                            value={designation}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Zákazka:</Trans>,
                        selector: <DealCodeSelect
                            customerId={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_DEAL_CODE)}
                            value={dealCode}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Odoslané: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_CREATED_AT)}
                            value={createAtDateString}
                        />,
                    },
                    {
                        label: <Trans>Posledná zmena: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LAST_CHANGE)}
                            value={lastChangeDateString}
                        />,
                    },
                    {
                        label: <Trans>Dátum potreby: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_DATE_REQUESTED)}
                            value={dateRequestedDateString}
                        />,
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ],
            ]}
        />;
    }

}

export default locationHOC(OrdersParams);